import React, {Component} from 'react';
import Header from "../component/header";
import MobileDetect from "mobile-detect";
import {detect} from "detect-browser";


import {RouteComponentProps} from "react-router-dom";

import './../css/room.css';
import {ChatRoomAction, UserDto} from "../App";
import {Subject} from "rxjs";
import Iframe from "react-iframe";

import {VirtualEventDataDto} from "../../backend/src/dto/RoomDto";

const privateChat = new Subject<[string, string]>();
const setChatActor = new Subject<string>();

export default class Room extends Component<RouteComponentProps & RoomProps, RoomState> {
    constructor(props: RouteComponentProps & RoomProps) {
        super(props);

        this.state = {
            actorId: props.user.defaultActorId,
            supportsUnity: Room.checkBrowserAndDevice()
        }

        // remove the old listeners if they exists
        this.props.socket.off(ChatRoomAction.roomDataLoaded);

        // @ts-ignore
        window['UnityLoaded'] = this.unityLoaded.bind(this);
    }

    public unityLoaded() {
        this.props.socket.emit(ChatRoomAction.loadRoomData, {
            roomId: this.props.id,
            userName: this.props.user?.firstName + " " + this.props.user?.lastName
        });

        this.props.socket.on(ChatRoomAction.roomDataLoaded, (roomData: VirtualEventDataDto) => {
            // @ts-ignore
            let loadRoom = document.getElementById('vrame-frame').contentWindow.LoadRoom;
            if (loadRoom) {
                loadRoom(roomData);
            }
        });
    }

    public componentDidMount() {
        setChatActor.subscribe(actorId => {
            this.setPhotoActorIdToUser(actorId);
        });

        privateChat.subscribe(idTuple => {
            this.props.inviteUser(idTuple[0], idTuple[1])
        });

        this.props.socket.on('reconnect', this.onReconnect);
    }

    public componentWillUnmount() {
        this.props.socket.off('reconnect', this.onReconnect);
    }

    private onReconnect = () => {
        this.props.socket.emit(ChatRoomAction.addActorToUser, {
            id: this.props.user.id,
            actorId: this.state.actorId
        });
    };

    public render() {
        return (
            <>
                <Header title={"Raum " + this.props.id}/>
                <div className="container">
                    <main>
                        {
                            this.state.supportsUnity
                                ?
                                <div className={"row middle-xs"}>
                                    <div className={'col-xs-12'}>
                                        <Iframe id="vrame-frame"
                                                url="/room.html"
                                                width="100%"
                                                height="610px"
                                                scrolling={"no"}
                                                />
                                    </div>
                                </div>
                                :
                                <div className={"unsupported"}> Ihr Webbrowser unterstützt die 3D Visualisierung leider
                                    nicht. </div>
                        }
                    </main>
                </div>
            </>
        )
    }

    private setPhotoActorIdToUser(actorId: string) {
        this.props.socket.emit(ChatRoomAction.addActorToUser, {
            id: this.props.user.id,
            actorId
        });

        this.setState({
            actorId
        })
    }

    private static checkBrowserAndDevice(): boolean {
        switch (detect()?.name) {
            case 'safari':
            case 'opera':
                return false;
        }

        return !new MobileDetect(window.navigator.userAgent).mobile();
    }
}

// @ts-ignore
window['SetPhotonActorID'] = function (actorId: string) {
    setChatActor.next(actorId);
}

let debounce: any;
// @ts-ignore
window['StartChatWithActors'] = function (thisActorID: string, otherActorID: string) {
    if (debounce) {
        clearTimeout(debounce);
    }

    debounce = setTimeout(() => {
        privateChat.next([thisActorID, otherActorID])
    }, 100);
}

type RoomState = {
    actorId: string
    supportsUnity: boolean,
}

type RoomProps = {
    id: string
    socket: SocketIOClient.Socket,
    user: UserDto,
    inviteUser: (thisActorID: string, otherActorID: string) => void;
}

