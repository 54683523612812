import React, {Component} from 'react';
import Header from "../component/header";

import './../css/room.css';
import {UserDto} from "../App";
import Iframe from "react-iframe";

export default class Mainroom extends Component<RoomProps, RoomState> {
    public render() {
        return (
            <>
                <Header title={"Vortragsstream"}/>
                <div className="container">
                    <main>
                        {/* the reason to not have a form is to avoid default commit events */}
                        <div className={"row middle-xs"}>
                            <div className={'col-xs-12'}>
                                <Iframe url="/mainroom.html"
                                        width="100%"
                                        height="610px"
                                        scrolling={"no"}
                                />
                            </div>
                        </div>
                    </main>
                </div>
            </>
        );
    }
}

type RoomState = {
}

type RoomProps = {
    socket: SocketIOClient.Socket,
    user: UserDto,
}

