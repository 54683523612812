import React, {RefObject, useEffect, useRef} from "react";

import './chat.css'
import {UserDto} from "../App";

export type ChatUser = {
    id: string,
    name: string,
    email: string,
    actorId: string,
    socketId: string
}

export enum ChatMessageType {
    text,
    data,
    system
}

export type WhisperMessage = {
    id: string,
    sourceUserId: string,
    targetUserId: string,
    userId: string,
    time: string
    type: ChatMessageType,
    content: string,
}

type WhisperChatProps = {
    socket: SocketIOClient.Socket,
    user: UserDto,
    whisperTo: ChatUser,
    messages: WhisperMessage[],
    onSend: (message: string) =>  void,
    onHide: () => void,
}

type WhisperChatState = {
    message: string,
}

export class WhisperChat extends React.Component<WhisperChatProps, WhisperChatState> {
    private readonly input : RefObject<any>

    constructor(props: WhisperChatProps) {
        super(props);

        this.input = React.createRef();
        this.state = {
            message: '',
        }
    }

    componentDidMount() {
        this.tryFocus();
    }

    componentDidUpdate(prevProps: Readonly<WhisperChatProps>, prevState: Readonly<WhisperChatState>, snapshot?: any) {
        if (prevProps.whisperTo.id !== this.props.whisperTo.id) {
            this.tryFocus();
        }
    }

    private tryFocus() {
        if (this.input.current) {
            this.input.current.focus();
        }
    }

    render() {
        return (
            <div className={"chat-wrapper"}>
                <div className={"chat-room-header"}>
                    Private Nachrichten mit <b>{this.props.whisperTo.name}</b>
                    <span className={"material-icons"} onClick={() => this.props.onHide()}>close</span>
                </div>
                <WhisperChatRoomBox messageList={this.props.messages} />
                <div className={"message-box"}>
                    <input
                        ref={this.input}
                        type="text"
                        value={this.state.message}
                        placeholder={"Private Nachricht an: " + this.props.whisperTo.name}
                        onChange={(e) => {
                            this.setState({message: e.target.value });
                        }}
                        onKeyUp={(e) => {
                            if (e.keyCode === 13) {
                                this.props.onSend(this.state.message);

                                this.setState({
                                    message: ''
                                });
                            }
                        }}
                    />
                </div>
            </div>
        )
    }
}

function WhisperChatRoomBox({messageList} : {messageList: WhisperMessage[]}) {
    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        const box = document.getElementById('chat-room-box');
        const bounding = box?.getBoundingClientRect();

        if (bounding
            && bounding.top >= 0
            && bounding.left >= 0
            && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
            //@ts-ignore
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }

    useEffect(scrollToBottom, [messageList]);

    return (
        <div id={"chat-room-box"} className={"chat-room"}>
            {messageList.map((message: WhisperMessage) => {
                if (message.type === ChatMessageType.system) {
                    return (
                        <div className={"chat-room-message-wrapper system"} key={"message-user" + message.id}>
                            <span className={"chat-room-time"}>{message.time}</span>
                            <span className={"chat-room-user"}>{message.userId}:</span>
                            <span className={"chat-room-message"}>{message.content}</span>
                        </div>
                    );
                }

                return (
                    <div className={"chat-room-message-wrapper"} key={"message-user" + message.id}>
                        <span className={"chat-room-time"}>{message.time}</span>
                        <span className={"chat-room-user"}>{message.userId}:</span>
                        <span className={"chat-room-message"}>{message.content}</span>
                    </div>
                );
            })}
            <div ref={messagesEndRef} />
        </div>
    )
}
