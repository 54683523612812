const env = process?.env?.NODE_ENV ? process?.env?.NODE_ENV : 'production';
const isDev = env === 'development';

export let socketHostName: string;
// could be done via env variables for CI/CD
if (!isDev) {
    socketHostName = 'https://test.meetdigital.at';

} else {
    socketHostName = 'http://localhost:4747';
}
