import React, {RefObject} from 'react';

import './rtc.css';
import {ChatUser} from "./chat";
import {ChatRoomAction, UserDto} from "../App";
import Client, {Configuration} from "../ion/client";
import {IonSFUJSONRPCSignal} from "../ion/signal/json-rpc-impl";
import {LocalStream} from "../ion/stream";

type MediaStreamPointRef = {
    refVideo: RefObject<HTMLVideoElement>,
    stream: MediaStream | null,
}

type VideoChatEvent = {
    roomId: string;
    streamId: string;
    userId: string;
}

type WebrtcProps = {
    roomId: string;
    currentUsers: ChatUser[];
    socket: SocketIOClient.Socket;
    user: UserDto;
}

type WebrtcState = {
    localStreamInitialized: boolean;
    mediaServerInitialized: boolean;
    mediaServer: any | null;
    turnServerInitialized: boolean;
    turnServer: TurnServer | null;
    audioEnabled: boolean;
    videoEnabled: boolean;
    errorMessage: string | null;
    remoteStreams: Map<string, MediaStream>;
    remoteUsers: Map<string, string>;
    mutedUsers: Map<string, boolean>;
}

interface TurnServer {
    url: string;
    username?: string;
    password?: string;
}

export class Webrtc extends React.Component<WebrtcProps, WebrtcState> {
    private readonly localVideoRef: MediaStreamPointRef;

    constructor(props: WebrtcProps) {
        super(props);

        this.localVideoRef = {
            refVideo: React.createRef(),
            stream: null,
        }
        this.state = {
            mediaServerInitialized: false,
            localStreamInitialized: false,
            mediaServer: null,
            turnServerInitialized: false,
            turnServer: null,
            audioEnabled: true,
            videoEnabled: true,
            errorMessage: null,
            remoteStreams: new Map(),
            remoteUsers: new Map(),
            mutedUsers: new Map(),
        }

        this.props.socket.on(ChatRoomAction.linkVideoChatUser, (e: VideoChatEvent) => {
            const copy = new Map(this.state.remoteUsers);
            copy.set(e.userId, e.streamId);

            this.setState({
                remoteUsers: copy
            })
        });
        this.props.socket.on(ChatRoomAction.videoChatUnmuted, (e: VideoChatEvent) => {
            this.setMuted(e.userId, false);
        });
        this.props.socket.on(ChatRoomAction.videoChatMuted, (e: VideoChatEvent) => {
            this.setMuted(e.userId, true);
        });
    }

    private setMuted(userId: string, value: boolean) {
        const copied = new Map(this.state.mutedUsers);
        copied.set(userId, value);

        this.setState({
            mutedUsers: copied
        });
    }

    async componentWillMount() {
        const response = await fetch(
            "https://mgr.turn.meetdigital.at/server"
        );
        const body: Promise<TurnServer | null> = await response.json();
        let turnServer = await body;

        console.log("received turn server from turnmgr:", turnServer);

        if (!turnServer) {
            turnServer = {
                url: "turn:m1.turn.meetdigital.at:3478",
                username: "goinplaces",
                password: "D2E625FF-C317-4805-82B1-BF107BEA0AFB"
            };
        }

        this.setState({
            turnServerInitialized: true,
            turnServer
        });
    }

    componentDidMount() {
        this.openLocalStream();
    }

    componentDidUpdate(prevProps: Readonly<WebrtcProps>, prevState: Readonly<WebrtcState>) {
        if (this.state.localStreamInitialized && this.state.turnServerInitialized && !this.state.mediaServerInitialized) {
            this.initMediaServer();
        }

        this.cleanupMutedPeersOnDisconnect(prevProps);
        this.sendStateToNewUsers(prevProps);
    }

    private cleanupMutedPeersOnDisconnect(prevProps: Readonly<WebrtcProps>) {
        const copied = new Map(this.state.mutedUsers);
        const currentUserIds = this.props.currentUsers.map((u) => u.id);
        let changed = false;
        prevProps.currentUsers.forEach((prevUser) => {
            const prevUserId = prevUser.id;
            const result = currentUserIds.find((value) => prevUserId === value);
            if (!result) {
                copied.delete(prevUserId);
                changed = true;
            }
        });
        if (changed) {
            this.setState({
                mutedUsers: copied
            });
        }
    }

    private sendStateToNewUsers(prevProps: Readonly<WebrtcProps>) {
        const prevUserIds = prevProps.currentUsers.map((u) => u.id);
        let changed = false;
        this.props.currentUsers.forEach((u) => {
            const userId = u.id;
            const result = prevUserIds.find((value) => userId === value);
            changed = changed || !result;
        });

        if (changed) {
            const stream = this.localVideoRef.stream;
            if (stream) {
                const event = {
                    roomId: this.props.roomId,
                    streamId: stream.id,
                    userId: this.props.user.id,
                };

                this.props.socket.emit(ChatRoomAction.linkVideoChatUser, event);
                if (this.state.audioEnabled) {
                    this.props.socket.emit(ChatRoomAction.videoChatUnmuted, event);
                } else {
                    this.props.socket.emit(ChatRoomAction.videoChatMuted, event);
                }
            }
        }
    }

    componentWillUnmount() {
        this.closeLocalStream();
        this.disconnectFromMediaServer();
    }

    public render() {
        return (
            <>
                {this.state.errorMessage != null
                    ?
                    <p className={"video-chat-error"}>{this.state.errorMessage}</p>
                    :
                    <div className="video-chat-container">
                        <div key="local-video-container" className="local-video-container">
                            <div key="hidden-video-toolbar" className={"hidden-local-video-toolbar"}/>
                            <video
                                key={"local-stream"}
                                muted
                                ref={this.localVideoRef.refVideo}
                                autoPlay playsInline
                                className={'local-video'}
                            />
                            <div key="local-video-toolbar" className={"local-video-toolbar"}>
                                <div key={"mic-item"} className={"local-video-toolbar-item"} onClick={() => this.toggleMute()}>
                                    <span key={"mic"} className={"material-icons"}>{this.state.audioEnabled ? "mic" : "mic_off"}</span>
                                </div>
                                <div key={"cam-item"} className={"local-video-toolbar-item"} onClick={() => this.toggleCam()}>
                                    <span key={"cam"} className={"material-icons"}>{this.state.videoEnabled ? "videocam" : "videocam_off"}</span>
                                </div>
                            </div>
                        </div>
                        <div key="remote-video-container" className="remote-video-container">
                            {this.props.currentUsers.filter((u) => u.id !== this.props.user.id).map((u) => {
                                    const sid = this.state.remoteUsers.get(u.id) || "";
                                    const stream = this.state.remoteStreams.get(sid);
                                    const muted = this.state.mutedUsers.get(u.id) || false;

                                    return (
                                        <WebrtcRemoteVideo
                                            key={u.id}
                                            stream={stream}
                                            muted={muted}
                                            user={u}
                                        />
                                    );
                            })}
                        </div>
                    </div>
                }
            </>
        )
    }

    private toggleMute() {
        const stream = this.localVideoRef.stream;
        if (stream) {
            const enabled = !this.state.audioEnabled;
            stream.getAudioTracks()[0].enabled = enabled;

            this.setState({
                audioEnabled: enabled
            });

            const event: VideoChatEvent = {
                streamId: stream.id,
                roomId: this.props.roomId,
                userId: this.props.user.id,
            }
            if (enabled) {
                this.props.socket.emit(ChatRoomAction.videoChatUnmuted, event);
            } else {
                this.props.socket.emit(ChatRoomAction.videoChatMuted, event);
            }
        }
    }

    private toggleCam() {
        const stream = this.localVideoRef.stream;
        if (stream) {
            stream.getVideoTracks()[0].enabled = !this.state.videoEnabled;

            this.setState({
                videoEnabled: !this.state.videoEnabled
            });
        }
    }

    private initMediaServer() {
        console.log("connecting to media server...");

        const servers = [];
        if (this.state.turnServer) {
            servers.push({
                urls: this.state.turnServer.url + "?transport=udp",
                username: this.state.turnServer.username,
                credential: this.state.turnServer.password
            });
            servers.push({
                urls: this.state.turnServer.url + "?transport=tcp",
                username: this.state.turnServer.username,
                credential: this.state.turnServer.password
            });
        }

        const config: Configuration = {
            iceServers: servers,
            codec: "vp8"
        };

        const signalLocal = new IonSFUJSONRPCSignal(
            "wss://m1.turn.meetdigital.at/ws"
        );

        const clientLocal = new Client(signalLocal, config);
        signalLocal.onopen = () => {
            clientLocal.join(this.props.roomId).then(() => {
                clientLocal.publish(this.localVideoRef.stream!! as LocalStream);
            });
        };
        signalLocal.onclose = (e: any) => console.log("websocket connection closed", e); // todo what to do on close?
        signalLocal.onerror = (e: any) => console.log("error in websocket connection", e); // todo what to do if websocket connection breaks?

        clientLocal.ontrack = (track: MediaStreamTrack, stream: MediaStream) => {
            console.log("got track", track.id, "for stream", stream.id);
            if (track.kind === "video") {
                let added = false;
                track.onunmute = () => {
                    if (!added) {
                        let copyForAdd = new Map(this.state.remoteStreams);
                        copyForAdd.set(stream.id, stream);

                        this.setState({
                            remoteStreams: copyForAdd
                        })
                        added = true;

                        let removed = false;
                        stream.onremovetrack = () => {
                            console.log("remove track (stream)", track.id, "from stream", stream.id);
                            if (!removed) {
                                let copyForRemove = new Map(this.state.remoteStreams);
                                copyForRemove.delete(stream.id);

                                this.setState({
                                    remoteStreams: copyForRemove
                                })
                                removed = true;
                            }
                        };
                    }
                };
            }
        }

        this.setState({
            mediaServer: clientLocal,
            mediaServerInitialized: true,
        });
    }

    private disconnectFromMediaServer() {
        console.log("disconnecting from media server...");
        this.state.mediaServer.close();
    }

    private openLocalStream() {
        LocalStream.getUserMedia({
            codec: "vp8",
            resolution: "qvga",
            audio: true,
            simulcast: false
        }).then((stream: LocalStream) => {
                if (this.localVideoRef.refVideo.current) {
                    this.localVideoRef.refVideo.current.srcObject = stream;
                    this.localVideoRef.stream = stream;

                    console.log("local stream id: ", stream.id);
                    console.log("local resolution: ", stream.getVideoTracks()[0].getSettings().width, "x", stream.getVideoTracks()[0].getSettings().height);

                    this.props.socket.emit(ChatRoomAction.linkVideoChatUser, {
                        roomId: this.props.roomId,
                        streamId: stream.id,
                        userId: this.props.user.id,
                    });
                }

                this.setState({
                    localStreamInitialized: true
                });
            },
            e => {
                console.error(e);

                const error = e.name || "unknown"

                if (error.endsWith("NotFoundError")) {
                    this.setState({
                        errorMessage: "No suitable devices found for joining the Videochat."
                    })
                } else if (error === "NotReadableError" || error === "TrackStartError") {
                    this.setState({
                        errorMessage: "It seems another process (or tab) uses the camera and microphone that is necessary for joining the Videochat."
                    })
                } else if (error === "OverconstrainedError" || error === "ConstraintNotSatisfiedError") {
                    this.setState({
                        errorMessage: "It seems your hardware is not compatible with this Videochat."
                    });
                } else if (error === "NotAllowedError" || error === "PermissionDeniedError") {
                    this.setState({
                        errorMessage: "Permission denied. Please reload the page and allow permissions to join the Videochat."
                    })
                } else {
                    this.setState({
                        errorMessage: "An unknown error occurred. We're very sorry for the inconvenience."
                    })
                }
            });
    }

    private closeLocalStream() {
        if (this.localVideoRef.refVideo.current) {
            if (this.localVideoRef.stream) {
                this.localVideoRef.stream.getTracks().forEach((track) => track.stop());

                this.localVideoRef.refVideo.current.srcObject = null;
                this.localVideoRef.stream = null;
            }
        }
    }
}

type WebrtcRemoteVideoProps = {
    stream: MediaStream | undefined,
    muted: boolean,
    user: ChatUser | null,
}

type WebrtcRemoteVideoState = {}

class WebrtcRemoteVideo extends React.Component<WebrtcRemoteVideoProps, WebrtcRemoteVideoState> {
    private readonly videoRef: MediaStreamPointRef;

    constructor(props: WebrtcRemoteVideoProps) {
        super(props);

        this.videoRef = {
            refVideo: React.createRef(),
            stream: props.stream || null,
        }
        this.state = {}
    }

    componentDidMount() {
        if (this.props.stream && this.videoRef.refVideo.current) {
            this.videoRef.refVideo.current.srcObject = this.props.stream
        }
    }

    componentDidUpdate(prevProps: Readonly<WebrtcRemoteVideoProps>, prevState: Readonly<WebrtcRemoteVideoState>) {
        if (this.props.stream && this.videoRef.refVideo.current) {
            if (!prevProps.stream || prevProps.stream.id !== this.props.stream.id) {
                this.videoRef.refVideo.current.srcObject = this.props.stream
            }
        }
    }

    public render() {
        if (this.props.stream) {
            return <div className={"remote-video-box"}>
                {this.props.muted
                    ? <div className={"remote-video-muted-indicator"}><span className={"material-icons"}>mic_off</span>
                    </div>
                    : <></>}
                <div className={"remote-video-label"}>{this.props.user?.name || ""}</div>
                <video
                    key={this.props.stream.id}
                    ref={this.videoRef.refVideo}
                    autoPlay playsInline
                    className={'remote-video'}
                />
            </div>;
        } else {
            return <></>;
        }
    }
}
