import React from 'react';
import {matchPath, Route, RouteComponentProps, Switch} from "react-router-dom";

import './css/reset.css';
import './css/index.css';
import './css/flexboxgrid.css';

import Login from "./page/login";
import io from "socket.io-client";
import Lobby from "./page/lobby";
import Room from "./page/room";
import Main from "./page/main";
import MainRoom from "./page/mainroom";
import PrivateChat from "./page/privatechat"
import {socketHostName} from "./config";
import {Chat, ChatUser} from "./component/chat";
import {ChatMessageType, WhisperChat, WhisperMessage} from "./component/whisperchat"

export type UserDto = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: number;
    updatedAt: number;
    token: string;
    defaultActorId: string;
}

export enum ChatRoomAction {
    hello = 'hello',
    addActorToUser = 'add-actor-id-to-user',
    registered = 'registered',
    created = 'room-created',
    sendMessage = 'chat-message',
    sendWhisperMessage = 'whisper-message',
    sendToUser = 'send-to-user',
    quit = 'room-quit',
    messages = 'messages',
    whisperMessages = 'whisper-messages',
    userList = 'user-list',
    globalUserList = 'global-user-list',
    invite = 'invite',
    loadLobbyList = 'load-lobby-list',
    lobbyList = 'lobby-list',
    roomDataLoaded = 'room-data-loaded',
    loadRoomData = 'load-room-data',
    inviteToRoom = 'invite-to-room',
    declineInvite = 'decline-invite',
    left = 'left',
    joined = 'joined',
    leaveRoom = 'leave-room',
    disconnect = 'disconnect',
    connect = 'connect',
    videoChatMuted = 'videochat-muted',
    videoChatUnmuted = 'videochat-unmuted',
    linkVideoChatUser = "videochat-link-user",
}

export const socket: SocketIOClient.Socket = io(socketHostName, {transports: ['websocket']});

socket.on('reconnect_attempt', () => {
    socket.io.opts.transports = ['polling', 'websocket'];
});

type InviteDto = {
    roomName: string,
    invitationFrom: string,
    inviteActorId: string
}

type WhisperState = {
    user: ChatUser,
    unreadMessages: number,
    messages: WhisperMessage[],
    hidden: boolean,
}

type AppState = {
    user?: UserDto,
    chatDocked: boolean,
    chatUnreadMessages: number,
    chatUsers: ChatUser[],
    globalUsers: ChatUser[],
    whisperTo?: ChatUser,
    whispers: Map<string, WhisperState>,
    hideOverlays: boolean
}

class App extends React.Component<RouteComponentProps, AppState> {
    private readonly roomRef: React.RefObject<any>
    private readonly mainRoomRef: React.RefObject<any>

    private lastInvite ?: InviteDto
    private lastInviteDate: number = 0

    private lastInviteMsg ?: any
    private lastInviteMsgDate: number = 0;

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            user: undefined,
            chatDocked: false,
            chatUnreadMessages: 0,
            chatUsers: [],
            globalUsers: [],
            whispers: new Map<string, WhisperState>(),
            hideOverlays: false,
        };

        this.roomRef = React.createRef()
        this.mainRoomRef = React.createRef()
    }

    componentDidMount() {
        if (this.state.user) {
            this.initApp();
        } else {
            fetchUserFromSession(this.setUser);
        }
    }

    componentDidUpdate(prevProps: Readonly<RouteComponentProps>, prevState: Readonly<AppState>, snapshot?: any) {
        if (!prevState.user && this.state.user) {
            this.initApp()
        }
        if (!prevState.hideOverlays && this.state.hideOverlays) {
            setTimeout(() => {
                this.setState({
                    hideOverlays: false,
                })
            }, 100);
        }
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({
                chatUnreadMessages: 0,
                chatDocked: !!this.state.whisperTo,
            });
        }
    }

    componentWillUnmount() {
        socket.off('reconnect', this.onReconnect);
    }

    render() {
        if (!this.state.user) {
            return <Login socket={socket} setUser={this.setUser}/>;
        }

        return (
            <>
                <Switch>
                    <Route exact path="/">
                        <Main socket={socket}/>
                    </Route>
                    <Route exact path="/lobby"><Lobby socket={socket}/></Route>
                    <Route exact path="/mainroom">
                        <MainRoom socket={socket}
                                  user={this.state.user!!}
                                  ref={this.mainRoomRef}/>
                    </Route>
                    <Route exact
                           path="/room/:roomId"
                           render={(props) => {
                               return <Room
                                   ref={this.roomRef}
                                   socket={socket}
                                   id={String(props.match.params.roomId)}
                                   user={this.state.user!!}
                                   inviteUser={this.inviteUserIntoNewRoom.bind(this)}
                                   {...props}
                               />
                           }}
                    />
                    <Route exact
                           path="/private/:chatId"
                           render={(props) => {
                               return <PrivateChat
                                   socket={socket}
                                   id={String(props.match.params.chatId)}
                                   user={this.state.user!!}
                                   chatUsers={this.state.chatUsers || []}
                                   {...props}
                               />
                           }}
                    />
                </Switch>
                <Route children={(props) => {
                    const mainroomMatch: any = matchPath(props.location.pathname, {
                        path: "/mainroom",
                        exact: true,
                        strict: true,
                    });

                    const roomMatch: any = matchPath(props.location.pathname, {
                        path: "/room/:roomId",
                        exact: true,
                        strict: true
                    });

                    const chatMatch: any = matchPath(props.location.pathname, {
                        path: "/private/:chatId",
                        exact: true,
                        strict: true,
                    });
                    const privateChatId = chatMatch?.params?.chatId;

                    const invite = privateChatId ? (lhs: string, rhs: string) => {
                        this.inviteUserToRoom(lhs, rhs, String(privateChatId))
                    } : this.inviteUserIntoNewRoom.bind(this);

                    const roomIdObj = privateChatId || roomMatch?.params?.roomId || (mainroomMatch != null ? "Vortragsstream" : null);
                    const roomId = roomIdObj ? String(roomIdObj) : null;

                    console.log(mainroomMatch, roomMatch, chatMatch, roomId);

                    const user = {...this.state.user!!, actorId: this.state.user!!.defaultActorId};
                    const activeWhispers = Array.from(this.state.whispers.values()).filter((w) => !w.hidden);
                    const unreadWhispers = activeWhispers.map((it) => it.unreadMessages).reduce((lhs, rhs) => lhs + rhs, 0);

                    const chatUsers = this.state.chatUsers || [];

                    return <footer id={"footer-chat"}>
                        <div className={"container"}>
                            {roomId != null
                                ? <div className={this.state.chatDocked || this.state.whisperTo ? "hidden" : ""}>
                                    <Chat
                                        user={user}
                                        chatRoomName={roomId!!}
                                        chatRoomCaption={privateChatId ? "Privater Videochat" : roomId}
                                        socket={socket}
                                        onMessage={() => this.onChatMessage()}
                                        onHide={() => this.setState({chatDocked: true})}
                                    />
                                </div>
                                : <></>
                            }

                            {this.state.whisperTo
                                ? <WhisperChat
                                    user={user}
                                    whisperTo={this.state.whisperTo}
                                    socket={socket}
                                    messages={this.state.whispers.get(this.state.whisperTo.id)?.messages || []}
                                    onSend={(message: string) => this.whisperMessage(this.state.whisperTo!!, message)}
                                    onHide={() => this.setState({whisperTo: undefined})}
                                />
                                : <></>
                            }

                            <div className={"row"}>
                                <div className={'col-xs-12'}>
                                    <div className={"user-list whispers"}>
                                        Private Nachrichten
                                        <span className="material-icons">chat_bubble_outline</span>
                                        {unreadWhispers > 0
                                            ? <span className={"badge badge-overlap"}>{unreadWhispers}</span>
                                            : <></>
                                        }

                                        {!this.state.hideOverlays
                                            ? <ul>
                                                {activeWhispers.length > 0
                                                    ? activeWhispers.map(state =>
                                                        <li key={"whisper-with-" + state.user.id}
                                                            onClick={() => this.whisper(state.user)}>
                                                            <span className={"material-icons right"}
                                                                  onClick={(event) => {
                                                                      event.stopPropagation();
                                                                      this.hideWhisper(state.user);
                                                                  }}>delete_outline</span>
                                                            {state.user.name} {state.unreadMessages > 0 ? <span
                                                            className={"badge badge-right"}>{state.unreadMessages}</span> : <></>}

                                                        </li>
                                                    ) : <li className={"disabled"} key={"whisper-with-none"}>Keine
                                                        Nachrichten</li>
                                                }
                                            </ul>
                                            : <></>
                                        }
                                    </div>

                                    <div className={"user-list"}>
                                        Active Users <span className="material-icons">supervisor_account</span><span
                                        className={"badge badge-gray badge-overlap"}>{this.state.globalUsers.length}</span>
                                        {!this.state.hideOverlays
                                            ? <ul>
                                                {this.state.globalUsers.map(user => {
                                                        return (user.email !== this.state.user?.email)
                                                            ? <li key={"active-" + user.id}>{user.name}
                                                                <span className="material-icons right"
                                                                      onClick={() => invite(this.state.user!!.defaultActorId, user.actorId)}>headset_mic</span>
                                                                <span className="material-icons right"
                                                                      onClick={() => this.whisper(user)}>chat_bubble_outline</span>
                                                            </li>
                                                            : <li className={"disabled"}
                                                                  key={"chat-" + user.id}>{user.name}</li>
                                                    }
                                                )}
                                            </ul>
                                            : <></>
                                        }
                                    </div>

                                    {roomId != null
                                        ? <>
                                            <div className={"user-list"}>
                                                Chat Users <span
                                                className="material-icons">supervisor_account</span><span
                                                className={"badge badge-green badge-overlap"}>{chatUsers.length}</span>
                                                {!this.state.hideOverlays
                                                    ? <ul>
                                                        {chatUsers.map(user => {
                                                                return (user.email !== this.state.user?.email)
                                                                    ? <li key={"chat-" + user.id}>{user.name}
                                                                        <span className="material-icons right"
                                                                              onClick={() => invite(this.state.user!!.defaultActorId, user.actorId)}>headset_mic</span>
                                                                        <span className="material-icons right"
                                                                              onClick={() => this.whisper(user)}>chat_bubble_outline</span>
                                                                    </li>
                                                                    : <li className={"disabled"}
                                                                          key={"chat-" + user.id}>{user.name}</li>
                                                            }
                                                        )}
                                                    </ul>
                                                    : <></>
                                                }
                                            </div>

                                            {this.state.chatDocked
                                                ? <div className={"chat-dock"}
                                                       onClick={() => this.setState({
                                                           chatDocked: false,
                                                           chatUnreadMessages: 0,
                                                           whisperTo: undefined,
                                                       })}>
                                                    Chat <span className={"material-icons"}>forum</span>
                                                    {this.state.chatUnreadMessages > 100
                                                        ? <span className={"badge badge-overlap"}>
                                                            100+
                                                          </span>
                                                        : (this.state.chatUnreadMessages > 0
                                                                ? <span className={"badge badge-overlap"}>
                                                                      {this.state.chatUnreadMessages}
                                                                  </span>
                                                                : <></>
                                                        )
                                                    }
                                                </div>
                                                : <div className={"chat-dock"}
                                                       onClick={() => this.setState({
                                                           chatDocked: true,
                                                           chatUnreadMessages: 0
                                                       })}>
                                                    Chat <span className={"material-icons"}>forum</span>
                                                </div>
                                            }
                                        </>
                                        : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </footer>
                }}/>
            </>
        );
    }

    private setUser = (user: UserDto) => {
        this.setState({
            user
        });
    }

    private setChatUsers = (chatUsers: ChatUser[]) => {
        if (!chatUsers) {
            chatUsers = [];
        }
        this.setState({
            chatUsers
        });
    }

    private setGlobalUsers = (globalUsers: ChatUser[]) => {
        if (!globalUsers) {
            globalUsers = [];
        }
        this.setState({
            globalUsers
        });
    }

    private whisper = (user: ChatUser) => {
        const whispers = new Map(this.state.whispers);
        const whisperState = whispers.get(user.id);
        if (whisperState) {
            whispers.set(user.id, {
                user: whisperState.user,
                messages: whisperState.messages,
                unreadMessages: 0,
                hidden: false
            });
        }

        this.setState({
            chatDocked: true,
            whisperTo: user,
            whispers,
            hideOverlays: true
        });
    }

    private initApp() {
        socket.off(ChatRoomAction.inviteToRoom);
        socket.on(ChatRoomAction.inviteToRoom, (msg: any) => {
            if (this.lastInviteMsg?.roomName === msg.roomName && (Date.now() - this.lastInviteMsgDate) < 5000) {
                return;
            } else if (msg.invitationFrom === (this.state.user?.firstName + " " + this.state.user?.lastName)) {
                this.props.history.push("/private/" + msg.roomName)
            } else if (window.confirm(msg.invitationFrom + ' hat sie zu einem privaten Chat eingeladen, wollen sie annehmen?')) {
                // please be aware the confirm only works on focused windows as an anti spam / fishing system
                // as a general solution a normal modal would be a possibility

                this.props.history.push("/private/" + msg.roomName)

                this.lastInviteMsg = msg;
                this.lastInviteMsgDate = Date.now();
            } else {
                socket.emit(ChatRoomAction.declineInvite, msg.roomName);
            }
        });

        socket.off(ChatRoomAction.globalUserList)
        socket.on(ChatRoomAction.globalUserList, this.setGlobalUsers);

        socket.off(ChatRoomAction.userList);
        socket.on(ChatRoomAction.userList, this.setChatUsers);
        socket.on(ChatRoomAction.left, () => {
            this.setChatUsers([]);
        });

        socket.off(ChatRoomAction.whisperMessages);
        socket.on(ChatRoomAction.whisperMessages, (message: WhisperMessage) => {
            const key = (message.sourceUserId === this.state.user!!.id) ? message.targetUserId : message.sourceUserId;

            const state = this.state.whispers.get(key);
            const messages = state?.messages || [];
            const unreadMessages = (state?.unreadMessages || 0);
            const user = state?.user || this.state.globalUsers.find((i) => i.id === key);

            if (user) {
                const newWhispers = new Map(this.state.whispers);
                newWhispers.set(key, {
                    user,
                    unreadMessages: unreadMessages + (this.state.whisperTo?.id !== key ? 1 : 0),
                    messages: [...messages, message],
                    hidden: false
                });

                this.setState({
                    whispers: newWhispers
                });
            }
        });

        socket.on('reconnect', this.onReconnect);
    }

    private onReconnect = () => {
        const user = this.state.user;
        if (user) {
            socket.emit('attach-to-session', user.token);
        }
    };

    private inviteUser(thisActorID: string, otherActorID: string, roomName?: string) {
        const user = this.state.user;
        if (!user) {
            return;
        }
        const now = Date.now();
        const room = roomName || (thisActorID + '+' + otherActorID);

        const inviteDto: InviteDto = {
            roomName: room,
            invitationFrom: user.firstName + " " + user.lastName,
            inviteActorId: otherActorID
        };

        if (this.lastInvite?.inviteActorId === inviteDto.inviteActorId
            && this.lastInvite?.roomName === inviteDto.roomName
            && (now - this.lastInviteDate) < 1000) {
            return;
        }

        socket.emit(ChatRoomAction.invite, inviteDto);

        this.setState({
            whisperTo: undefined,
            chatDocked: false,
            hideOverlays: true,
        });

        this.lastInvite = inviteDto;
        this.lastInviteDate = now;
    }

    private inviteUserIntoNewRoom(thisActorID: string, otherActorID: string) {
        this.inviteUser(thisActorID, otherActorID, undefined)
    }

    private inviteUserToRoom(thisActorID: string, otherActorID: string, roomName: string) {
        this.inviteUser(thisActorID, otherActorID, roomName)
    }

    private hideWhisper(targetUser: ChatUser) {
        const state = this.state.whispers.get(targetUser.id);
        if (state) {
            const newWhispers = new Map(this.state.whispers);
            newWhispers.set(targetUser.id, {
                user: targetUser,
                unreadMessages: state.unreadMessages,
                messages: state.messages,
                hidden: true
            });

            this.setState({
                whispers: newWhispers,
                whisperTo: this.state.whisperTo?.id === targetUser.id ? undefined : this.state.whisperTo
            });
        }
    }

    private whisperMessage(targetUser: ChatUser, message: string) {
        let date = new Date();
        let hours = (date.getHours() < 10 ? "0" : "") + date.getHours();
        let minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();

        const msg: WhisperMessage = {
            id: '',
            sourceUserId: this.state.user!!.id,
            targetUserId: targetUser.id,
            userId: this.state.user!!.firstName + " " + this.state.user!!.lastName,
            time: hours + ":" + minutes,
            type: ChatMessageType.text,
            content: message,
        };

        socket.emit(ChatRoomAction.sendWhisperMessage, msg);
    }

    private onChatMessage() {
        if (this.state.chatDocked) {
            this.setState({
                chatUnreadMessages: this.state.chatUnreadMessages + 1,
            });
        }
    }
}

function fetchUserFromSession(setUser: Function): void {
    const userString = window.sessionStorage.getItem('user');
    if (!userString) {
        return;
    }

    const user = JSON.parse(userString);
    if (!user.id) {
        return;
    }

    socket.emit('attach-to-session', user.token);

    setUser(user);
}

export default App;
