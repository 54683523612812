import React, {Component} from 'react';
import Header from "../component/header";
import {UserDto} from "../App";

export enum UserEvents {
    login = 'login',
    password = 'password',
    create = 'create',
    loginSuccess = 'login-success',
    loginError = 'login-error'
}

export default class Login extends Component<LoginProps, LoginState> {
    private _mounted : boolean;

    constructor(props: any) {
        super(props);
        this._mounted = false;

        this.state = {
            email: '',
            password: '',
            hidden: ''
        };
    }

    public componentDidMount() {
        this.props.socket.off(UserEvents.loginSuccess);
        this.props.socket.off(UserEvents.loginError);

        this.props.socket.on(UserEvents.loginSuccess, (user: UserDto) => {
            this.setState({
                error: undefined
            });
            setUserToSession(user, this.props.setUser);
        });

        this.props.socket.on(UserEvents.loginError, (data: any) => {
            console.log('error: ', data);
            this.setState({
                error: "Anmeldung fehlgeschlagen.",
                password: ""
            })
        });

        if (this.state.hidden !== '') {
            setTimeout(() => {
                if (this._mounted) {
                    this.setState({hidden: ''})
                }
            }, 6500);
        }

        this._mounted = true;
    }

    public componentWillUnmount() {
        this._mounted = false;
    }

    public render() {
        return (
            <div className={"login-page"}>
                <Header title={"Login"}/>
                <div className="container m-t-1em">
                    <main className={""}>
                        {/* the reason to not have a form is to avoid default commit events */}
                        <div className={"row"}>
                            <div className={"col-xs-10 col-xs-offset-1 col-md-4 col-md-offset-4"}>
                                <div className={"login-form " + this.state.hidden}>
                                    <h3>Login</h3>
                                    <div className={"row"}>
                                        <div className={"col-xs-12"}>
                                            <div className="row">
                                                <input type={"text"} value={this.state.email} placeholder={"Email"}
                                                       onChange={(e) => this.setState({email: e.target.value})}/>
                                            </div>
                                            <div className="row">
                                                <input type={"password"} value={this.state.password}
                                                       placeholder={"Password"}
                                                       onChange={(e) => this.setState({password: e.target.value})}
                                                       onKeyUp={(e) => {
                                                           if (e.keyCode === 13) {
                                                               this.processLogin(this.state.email, this.state.password)
                                                           }
                                                       }}
                                                />
                                            </div>
                                            {this.state.error
                                                ?
                                                <div className={"row error"}>
                                                    <p>{this.state.error}</p>
                                                </div>
                                                :
                                                <></>
                                            }
                                            <div className={"row"}>
                                                <button
                                                    onClick={() => this.processLogin(this.state.email, this.state.password)}>
                                                    Absenden
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        );
    }

    private processLogin(email: string, password: string): void {
        if (!this.props.socket.connected) {
            setTimeout(() => {
                this.processLogin(email, password);
            }, 200);
        } else {
            this.props.socket.emit(UserEvents.login, {
                email,
                password
            });
        }
    }
}

function setUserToSession(user: UserDto, setUser: Function): void {
    if (!user.id) {
        return;
    }

    window.sessionStorage.setItem('user', JSON.stringify(user));

    setUser(user);
}

type LoginState = {
    email: string;
    password: string;
    error?: string;
    hidden: string;
}

type LoginProps = {
    socket: SocketIOClient.Socket;
    setUser: any;
}

