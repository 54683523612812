import React, {useEffect, useRef} from "react";

import './chat.css'
import {ChatRoomAction, UserDto} from "../App";

export type ChatUser = {
    id: string,
    name: string,
    email: string,
    actorId: string,
    socketId: string
}

type ChatRoom = {
    id: string,
    name: string
}

enum ChatMessageType {
    text,
    data,
    system
}

type ChatMessage = {
    id: string,
    userId: string,
    roomId: string
    type: ChatMessageType,
    time: string,
    content: any
}

type ChatUserDto = UserDto & {
    actorId: string
}

type ChatProps = {
    socket: SocketIOClient.Socket,
    chatRoomName: string,
    chatRoomCaption?:string,
    user: ChatUserDto,
    onMessage: (message: any) =>  void,
    onHide: () => void,
}

type ChatState = {
    message: string,
    messages: ChatMessage[],
    chatRoom: ChatRoom
}

export class Chat extends React.Component<ChatProps, ChatState> {

    constructor(props: ChatProps) {
        super(props);

        this.state = {
            message: '',
            messages: [],
            chatRoom: {id: '', name: ''},
        }
    }

    componentDidMount() {
        this.initRoom();
    }

    public componentDidUpdate(prevProps: Readonly<ChatProps>, prevState: Readonly<ChatState>) {
        if (prevProps.chatRoomName !== this.props.chatRoomName) {
            this.leaveRoom();
            this.setState({
                message: '',
                messages: [],
                chatRoom: {id: '', name: ''}
            });
            this.initRoom();
        }
    }

    componentWillUnmount() {
        this.leaveRoom();
    }

    private leaveRoom() {
        this.props.socket.off('reconnect', this.onReconnect);
        this.props.socket.off(ChatRoomAction.messages)
        this.props.socket.emit(ChatRoomAction.leaveRoom);
    }

    render() {
        return (
            <div className={"chat-wrapper"}>
                <div className={"chat-room-header"}>
                    <b>{this.props.chatRoomCaption ? this.props.chatRoomCaption : ""}</b>
                    <span className={"material-icons"} onClick={() => this.props.onHide()}>close</span>
                </div>
                <ChatRoomBox messageList={this.state.messages} />
                <div className={"message-box"}>
                    <input
                        type="text"
                        value={this.state.message}
                        onChange={(e) => {
                            this.setState({message: e.target.value });
                        }}
                        onKeyUp={(e) => {
                            if (e.keyCode === 13) {
                                this.sendMessage(
                                    this.props.user.firstName + " " + this.props.user.lastName,
                                    this.state.message
                                );

                                this.setState({
                                    message: ''
                                });
                            }
                        }}
                    />
                </div>
            </div>
        )
    }

    private initRoom = () => {
        this.props.socket.off(ChatRoomAction.joined)
        this.props.socket.on(ChatRoomAction.joined,(chatRoom: ChatRoom) => {
            this.setState({chatRoom});
        });

        this.props.socket.off(ChatRoomAction.messages)
        this.props.socket.on(ChatRoomAction.messages, (chatMessage: ChatMessage) => {
            if(chatMessage.roomId !== this.state.chatRoom.id) {
                console.log("ignoring a message to " + chatMessage.roomId + " because this room is " + this.state.chatRoom.id);
                return;
            }

            const messages = [...this.state.messages];

            messages.push(chatMessage);

            this.setState({
                messages: [...messages]
            })

            this.props.onMessage(chatMessage);
        });

        this.props.socket.emit('join-room', {
            roomName: this.props.chatRoomName
        });

        this.props.socket.on('reconnect', this.onReconnect);
    }

    private onReconnect = () => {
        console.log("websocket reconnected - rejoining room " + this.props.chatRoomName);
        this.props.socket.emit('join-room', {
            roomName: this.props.chatRoomName
        });
    }

    private sendMessage(userId: string, content: string, type: ChatMessageType = ChatMessageType.text) {
        if (!this.state.chatRoom.id) {
            console.log("did not yet join a chatroom, won't send the message")
            return;
        }

        let date = new Date();
        let hours = (date.getHours() < 10 ? "0" : "") + date.getHours();
        let minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();

        const chatMessage: ChatMessage= {
            id: '',
            roomId: this.state.chatRoom.id,
            userId,
            type,
            time: hours + ":" + minutes,
            content
        }

        this.props.socket.emit(ChatRoomAction.sendMessage, chatMessage)
    }
}

function ChatRoomBox({messageList} : {messageList: ChatMessage[]}) {
    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        const box = document.getElementById('chat-room-box');
        const bounding = box?.getBoundingClientRect();

        if (bounding
            && bounding.top >= 0
            && bounding.left >= 0
            && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
            //@ts-ignore
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }

    useEffect(scrollToBottom, [messageList]);

    return (
        <div id={"chat-room-box"} className={"chat-room"}>
            {messageList.map((message: ChatMessage) => {
                if (message.type === ChatMessageType.system) {
                    return (
                        <div className={"chat-room-message-wrapper system"} key={"message-user" + message.id}>
                            <span className={"chat-room-time"}>{message.time}</span>
                            <span className={"chat-room-user"}>{message.userId}:</span>
                            <span className={"chat-room-message"}>{message.content}</span>
                        </div>
                    );
                }

                return (
                    <div className={"chat-room-message-wrapper"} key={"message-user" + message.id}>
                        <span className={"chat-room-time"}>{message.time}</span>
                        <span className={"chat-room-user"}>{message.userId}:</span>
                        <span className={"chat-room-message"}>{message.content}</span>
                    </div>
                );
            })}
            <div ref={messagesEndRef} />
        </div>
    )
}
