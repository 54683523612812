import React from "react";

import './header.css'
import { slide as Menu } from "react-burger-menu";
import {Link} from "react-router-dom";

export default function Header({title}: {title: string}) {
    return (
        <header className={"header"}>
            { title !== "Login" ?
                <Menu disableAutoFocus>
                    <Link to={"/"}> Home </Link>
                    <Link to={"/mainroom"}> Livestream </Link>
                    <Link to={"/lobby"}> Networking </Link>
                </Menu>
                :
                <></>
            }
            {title}
        </header>
    )
}
