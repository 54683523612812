import React, {Component} from 'react';
import Header from "../component/header";

import './../css/room.css';
import {Link} from "react-router-dom";
import backgroundDesktop from "../img/tutorial-desktop.png";
import backgroundMobile from "../img/tutorial-mobile.png";

export default class Main extends Component<MainProps, any> {

    public render() {
        return (
            <>
                <Header title={"Hauptseite"}/>
                <div className="container m-t-1em">
                    <main>
                        <div className={"row middle-xs"}>
                            <div className={'col-xs-offset-1 col-xs-4 col-md-offset-4 col-md-2'}>
                                <Link className={'navigation-button'} to={"/mainroom"}>
                                    Livestream
                                </Link>
                            </div>
                            <div className={'col-xs-offset-1 col-xs-4 col-md-2'}>
                                <Link className={'navigation-button'} to={"/lobby"}>
                                    Networking
                                </Link>
                            </div>
                            <img className={"background-tutorial hide-sm"} src={backgroundMobile} width={"100%"} alt={""}/>
                            <img className={"background-tutorial show-sm"} src={backgroundDesktop} width={"100%"} alt={""}/>
                        </div>
                    </main>
                </div>
            </>
        );
    }
}

type MainProps = {
    socket: SocketIOClient.Socket,
}

