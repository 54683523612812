import React, {Component} from 'react';
import Header from "../component/header";
import {Link} from "react-router-dom";

import '../css/lobby.css';
import background from '../img/neueBastei_wintergarten.jpeg'
/*import backgroundDesktop from '../img/tutorial-desktop.png';
import backgroundMobile from '../img/tutorial-mobile.png';
 */
import {ChatRoomAction} from "../App";

export default class Lobby extends Component<LobbyProps, LobbyState> {

    constructor(props: any) {
        super(props);

        this.state = {
            roomList: [],
        };
    }

    public componentDidMount() {
        this.props.socket.emit(ChatRoomAction.loadLobbyList);

        this.props.socket.off(ChatRoomAction.lobbyList);

        this.props.socket.on(ChatRoomAction.lobbyList, (lobbyDto: LobbyDto) => {
            this.setState({
                roomList: lobbyDto.roomList
            })
        });
    }

    public render() {
        let rooms = this.state.roomList.filter(roomName => roomName !== 'Festsaal');

        let firstRooms: string[];
        let secondRooms: string[] = [];

        if (rooms.length > 2) {
            firstRooms = rooms.slice(0, 2);
            secondRooms = rooms.slice(2)
        } else {
            firstRooms = rooms;
        }


        return (
            <>
                <Header title={"Raumauswahl"}/>
                <div className="container m-t-1em">
                    <main>
                        <div className={"row middle-xs"}>
                            <div className={"col-xs-12"}>
                                <h2>Räume</h2>
                                <div className={"row room-list"}>
                                    <img className={"room-background"} src={background} width={"100%"} alt={""}/>
                                    { /*
                                    <img className={"room-background hide-sm"} src={backgroundMobile} width={"100%"} alt={""}/>
                                    <img className={"room-background show-sm"} src={backgroundDesktop} width={"100%"} alt={""}/>
                                    */ }
                                    <div className={"col-xs-12 m-t-8em"}>&nbsp;</div>
                                    {firstRooms.map(roomName => {
                                        return <div className={"col-xs-6"} key={roomName}>
                                            <Link key={roomName} className={"room-link"}
                                                  to={`/room/${roomName}`}
                                            >
                                                <Room name={roomName}/>
                                            </Link>
                                        </div>
                                    })}
                                    <div className={"col-xs-12 m-t-6em m-sm-t-10em m-md-t-12em"}>&nbsp;</div>
                                    {secondRooms.map(roomName => {
                                        return <div className={"col-xs-4"} key={roomName}>
                                            <Link key={roomName} className={"room-link"}
                                                  to={`/room/${roomName}`}
                                            >
                                                <Room name={roomName}/>
                                            </Link>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </>
        );
    }
}

function Room({name}: { name: string }) {
    return (
        <div className={"room"}>
            <div>{name}</div>
        </div>
    );
}

type LobbyState = {
    roomList: string[]
}

type LobbyProps = {
    socket: SocketIOClient.Socket;
}

type LobbyDto = {
    roomList: string[];
}
