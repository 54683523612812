import React, {Component} from 'react';
import Header from "../component/header";

import {RouteComponentProps} from "react-router-dom";

import './../css/room.css';
import {UserDto} from "../App";
import {ChatUser} from "../component/chat";

import {Webrtc} from "../component/webrtc";

export default class PrivateChat extends Component<RouteComponentProps & PrivateChatProps, PrivateChatState> {
    public render() {
        return (
            <>
                <Header title={"Private Chat"}/>
                <div className="container-fluid m-t-1em">
                    <main>
                        <Webrtc
                            roomId={this.props.id}
                            user={this.props.user}
                            socket={this.props.socket}
                            currentUsers={this.props.chatUsers}
                        />
                    </main>
                </div>
            </>
        )
    }
}

type PrivateChatState = {
}

type PrivateChatProps = {
    id: string,
    socket: SocketIOClient.Socket,
    user: UserDto,
    chatUsers: ChatUser[]
}

